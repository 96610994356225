import {
	AdminEnvironmentSettings,
	ClientPortalEnvironmentSettings,
	SharedEnvironmentSettings,
} from './environment-types';

export const ENVIRONMENT_CLIENT_PORTAL: ClientPortalEnvironmentSettings = {
	msal: {
		clientId: 'e0725ef2-e587-46c0-8bea-df834a6df6e3',
		authority: 'https://iataazurenonprodb2c.b2clogin.com/iataazurenonprodb2c.onmicrosoft.com/b2c_1a_signup_signin',
		redirectUri: '/logged-in',
		knownAuthority: 'https://iataazurenonprodb2c.b2clogin.com',
		scope: 'https://iataazurenonprodb2c.onmicrosoft.com/cargois-uat/App.User',
	},
};

export const ENVIRONMENT_ADMIN: AdminEnvironmentSettings = {
	msal: {
		clientId: 'a85a284f-7025-4de1-bceb-fad4cc10ac40',
		authority: 'https://iataazurenonprodb2c.b2clogin.com/iataazurenonprodb2c.onmicrosoft.com/b2c_1a_signup_signin',
		redirectUri: '/logged-in',
		knownAuthority: 'https://iataazurenonprodb2c.b2clogin.com',
		scope: 'https://iataazurenonprodb2c.onmicrosoft.com/cargois-uat/App.Admin',
	},
	staticReportEndpoint: 'https://uat-api.cargois.iata.org/static-reports',
	dataMonitoringEndpoint: 'https://uat-api.cargois.iata.org/data-monitor',
	emailServiceEndpoint: 'https://uat-api.cargois.iata.org/email',
};

export const ENVIRONMENT_SHARED: SharedEnvironmentSettings = {
	subscriptionsApiEndpoint: 'https://uat-api.cargois.iata.org',
	termsAndConditionsUrl:
		'https://iata--pprod.sandbox.my.site.com/csportal/s/support-view-category?category=Data_Products&topic=CargoIS&subtopic=Terminologies_and_Definitions_CargoIS',
	knowledgeHubUrl:
		'https://iata--pprod.sandbox.my.site.com/faq/s/faq-category?language=en_US&category=Data_Products&topic=CargoIS',
	contactUsUrl:
		'https://iata--pprod.sandbox.my.site.com/csportal/s/support-reach-us?topic=CargoIS',
	portalUrl: 'https://uat.cargois.iata.org',
	featureFlags: {
		enableWebTool: Boolean('true'),
	},
	msal: {
		enableLogging: false,
	},
	tinyMce: {
		apiKey: 'korwm7hnjofh1o9q9649sdfzh7rhpth5rgn577t5iywfr1by',
	},
};
